













import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { AppModule } from "@/store/App";
import Articles from "@/collections/Articles";
import IconContent from "@/components/common/IconContent.vue";
import Category from "@/models/Category";

@Component({
  components: {
    IconContent
  }
})
export default class ServicesBox extends Vue {
  articles: Articles = new Articles();
  loading = false;

  @Prop(Object) readonly category!: Category;

  @Watch("category")
  async onLoadCategory(obCategory: Category) {
    if (!this.articles || !obCategory) {
      return;
    }

    if (!this.articles.length && !this.loading) {
      this.load();
    }
  }

  mounted() {
    this.load();
  }

  async load() {
    const obCategory = this.category || this.getDefaultCategory();

    if (!obCategory || this.loading) {
      return;
    }

    this.loading = true;
    await this.articles
      .category(obCategory.id)
      .limit(3)
      .fetch();
    this.loading = false;
  }

  getDefaultCategory() {
    return AppModule.categoryCollection.filter({ slug: "services" }).first();
  }
}
