










import { Vue, Component, Prop } from "vue-property-decorator";
import MarkdownIt from "markdown-it";

@Component
export default class IconContent extends Vue {
  @Prop(String) readonly src!: string;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly content!: string;
  @Prop(String) readonly href!: string;
  @Prop(Object) readonly to!: string;

  md(sValue: string) {
    return new MarkdownIt().renderInline(sValue);
  }
}
